<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="exportData">
      <el-button class="textBtn" @click="handlePreview" type="text">导出</el-button>
    </div>
    <global-table row-key='id' :tableField="tableField" @tableAction="tableAction" @SelectionChange='SelectionChange' :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { exportForm, request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { MIME } from '@/assets/js/mime.js'
export default {
  components: Component.components,
  name: 'EvaluationQuery',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      selectTabData: [],
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
    this.supplierList()
  },

  methods: {
    // 获取考评结果数据列表
    myBidData () {
      let cjksTime = ''
      let cjjsTime = ''
      if (this.childData.searchData.createDate) {
        cjksTime = dayjs(this.childData.searchData.createDate[0]).format('YYYY-MM-DD HH:mm:ss')
        cjjsTime = dayjs(this.childData.searchData.createDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        number: this.childData.searchData.number,
        message: this.childData.searchData.message,
        quarter: this.childData.searchData.quarter,
        status: this.childData.searchData.status,
        isEffective: this.childData.searchData.isEffective,
        companyName: this.childData.searchData.companyName,
        startDate: cjksTime,
        endDate: cjjsTime
      }
      request('/api/evaluation/evaluation/queryAll?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },

    // 点击操作里的查看按钮
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'EvaluationDetails' }, query: { id: data.id } })
      }
    },

    // 获取公司数据列表
    supplierList () {
      var newOption = []
      request('/api/supplier/basicData/getForSelect', 'POST').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            newOption.push({
              label: `${~~item.erpCode}-${item.name}`,
              value: `${~~item.erpCode}`
            })
          })
          this.childData.searchList[5].option = newOption
        }
      })
    },

    // 导出
    handlePreview () {
      let cjksTime = ''
      let cjjsTime = ''
      if (this.childData.searchData.createDate) {
        cjksTime = dayjs(this.childData.searchData.createDate[0]).format('YYYY-MM-DD HH:mm:ss')
        cjjsTime = dayjs(this.childData.searchData.createDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        number: this.childData.searchData.number,
        message: this.childData.searchData.message,
        quarter: this.childData.searchData.quarter,
        status: this.childData.searchData.status,
        isEffective: this.childData.searchData.isEffective,
        companyName: this.childData.searchData.companyName,
        startDate: cjksTime,
        endDate: cjjsTime
      }
      var file = { name: '考评数据.xls' }
      exportForm('/api/evaluation/evaluation/poi', 'POST', obj, 'application/json').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },

    // 获取选中的数据
    SelectionChange (data) {
      this.selectTabData = data
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
.list{
  .exportData{
    padding: 8px 30px;
  }
  .textBtn{
    font-size: 16px;
  }
  .tableList{
    margin-top: 0;
  }
}
</style>
