// 考评列表页配置文件
export var SearchList = [
  { labe: '档案编号', code: 'number', type: 'input' },
  { labe: '档案描述', code: 'message', type: 'input' },
  { labe: '考评季度', code: 'quarter', type: 'input', placeholder: '如:2021-01' },
  {
    labe: '档案状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '新建', value: '1' },
      { label: '计算中', value: '2' },
      { label: '计算完成', value: '3' },
      { label: '计算失败', value: '4' },
      { label: '评分中', value: '5' },
      { label: '汇总完成', value: '6' },
      { label: '已发布', value: '7' },
      { label: '已作废', value: '8' }
    ]
  },
  {
    labe: '是否有效',
    code: 'isEffective',
    type: 'select',
    option: [
      { label: '是', value: 'true' },
      { label: '否', value: 'false' }
    ]
  },
  {
    labe: '公司',
    code: 'companyName',
    type: 'select',
    option: []
  },
  {
    labe: '建档日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var SearchData = {
  number: '',
  message: '',
  quarter: '',
  status: '',
  isEffective: '',
  companyName: '',
  createDate: ''
}
export var tableField = [
  // { type: 'input', selectType: 'selection', width: '', reserveSelection: true },
  {
    label: '档案状态',
    code: 'status',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.status === 1 ? '新建' : data.status === 2 ? '计算中' : data.status === 3 ? '计算完成' : data.status === 4 ? '计算失败' : data.status === 5 ? '评分中' : data.status === 6 ? '汇总完成' : data.status === 7 ? '已发布' : data.status === 8 ? '已作废' : ''
    }
  },
  { label: '档案编号', code: 'number', type: 'input', width: '150%' },
  { label: '档案描述', code: 'message', type: 'input', width: '250%' },
  { label: '考评模板', code: 'templateName', type: 'input', width: '250%' },
  { label: '考评季度', code: 'quarter', type: 'input', width: '80%' },
  { label: '考评日期从', code: 'startDate', type: 'input', width: '160%' },
  { label: '考评日期至', code: 'endDate', type: 'input', width: '160%' },
  // { label: '公司名称', code: 'companyName', type: 'input', width: '95%' },
  {
    label: '是否有效',
    code: 'isEffective',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.isEffective === true ? '是' : data.isEffective === false ? '否' : ''
    }
  },
  { label: '建档人', code: 'createUserId', type: 'input', width: '90%' },
  { label: '建档时间', code: 'createDate', type: 'input', width: '160%' },
  { label: '发布时间', code: 'releaseDate', type: 'input', width: '160%' },
  {
    label: '操作',
    code: 'act',
    width: '60%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []

// 考评详情页配置文件
export var SearchList1 = [
  {
    labe: '供应商',
    code: 'supperName',
    type: 'select',
    option: []
  },
  {
    labe: '采购品类',
    code: 'categoryName',
    type: 'select',
    option: [
      { label: '主板、板卡', value: 'PL001' },
      { label: 'OEM整机、防火墙等', value: 'PL002' },
      { label: '硬盘', value: 'PL003' },
      { label: 'SSD', value: 'PL004' },
      { label: '盘阵', value: 'PL005' },
      { label: '光纤HBA卡', value: 'PL006' },
      { label: '光纤模块', value: 'PL007' },
      { label: '外设', value: 'PL008' },
      { label: '自制品', value: 'PL009' },
      { label: '网卡', value: 'PL010' },
      { label: '线缆', value: 'PL011' },
      { label: 'CPU', value: 'PL012' },
      { label: 'GPU、显卡', value: 'PL013' },
      { label: '结构件、附件', value: 'PL014' },
      { label: '内存', value: 'PL015' },
      { label: '背板', value: 'PL016' },
      { label: '中板', value: 'PL017' },
      { label: '交换机', value: 'PL018' },
      { label: 'RAID卡', value: 'PL019' },
      { label: '电源', value: 'PL020' },
      { label: '风扇', value: 'PL021' },
      { label: '散热器', value: 'PL022' },
      { label: '包材', value: 'PL023' },
      { label: '标签', value: 'PL024' },
      { label: '资材类（固资、费用、礼品、印刷品等）', value: 'PL025' },
      { label: '外购软件', value: 'PL026' },
      { label: '服务', value: 'PL027' },
      { label: '商品', value: 'PL028' },
      { label: '第三方采购物料', value: 'PL029' },
      { label: '研发购原材料', value: 'PL030' },

      { label: '仙络原材料', value: 'XL001' }
    ]
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '未完成', value: '1' },
      { label: '已发布', value: '2' },
      { label: '已完成', value: '3' }
    ]
  }
]
export var SearchData1 = {
  supperName: '',
  categoryName: '',
  status: ''
}
export var tableField1 = [
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '90%',
    handle: (index, data) => {
      return data.status === 1 ? '新建' : data.status === 2 ? '已发布' : data.status === 3 ? '已反馈' : ''
    }
  },
  { label: '建议策略', code: 'strategy', type: 'input', width: '90%' },
  /*   {
    label: '风险等级',
    code: 'riskLevel',
    type: 'function',
    width: '90%',
    handle: (index, data) => {
      return data.riskLevel === 1 ? '低风险' : data.riskLevel === 2 ? '中风险' : data.riskLevel === 3 ? '高风险' : ''
    }
  }, */
  { label: '供应商编码', code: 'supperCode', type: 'input', width: '90%' },
  { label: '供应商名称', code: 'supperName', type: 'input', width: '150%' },
  { label: '采购品类名称', code: 'categoryName', type: 'input', width: '140%' },
  { label: '得分', code: 'score', type: 'input', width: '80%' },
  { label: '等级', code: 'level', type: 'input', width: '70%' },
  { label: '反馈说明', code: 'feedback', type: 'input', width: '130%' },
  { label: '行业排名', code: 'ranking', type: 'input', width: '80%' },
  {
    label: '评分明细',
    code: 'act',
    width: '90%',
    type: 'action',
    actionData: [
      {
        actionLabel: '评分明细',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData1 = []
